import { defineStore, storeToRefs } from 'pinia'
import { nextTick } from 'vue'
import bus, { EventBusTypes } from '@/utils/bus.ts'
import { useRecordStore } from '@/store/modules/recordStore'
import { useRoomStore } from '@/store/modules/roomStore.ts'
import { IRecordsKey, RecordsDirectResItem } from '@/api/message2/type'
import { showToast } from 'vant'
import i18n from '@/i18n/index.ts'
export const useRecordMessageStore = defineStore('messageStore', () => {
    const { GET_CHAT_RECORDS_KEY, GET_CHAT_RECORDS, SET_CHAT_RECORDS, HAS_CHAT_RECORDS } = useRecordStore()
    const { CUR_CHAT_RECORDS_KEY } = storeToRefs(useRecordStore())
    const { updateRoomInfo } = useRoomStore()

    const ADD_CHAT_RECORDS = (params: IRecordsKey, data: RecordsDirectResItem) => {
        const key = GET_CHAT_RECORDS_KEY(params)
        if (HAS_CHAT_RECORDS(key)) {
            const records = GET_CHAT_RECORDS(key, 'data') || []
            records.push(data)
            console.log('消息已添加', key, records)
        }
    }
    const DEL_CHAT_RECORDS_BY_ID = (msgId: string) => {
        if (HAS_CHAT_RECORDS(CUR_CHAT_RECORDS_KEY.value)) {
            const records = GET_CHAT_RECORDS(CUR_CHAT_RECORDS_KEY.value, 'data') as RecordsDirectResItem[]
            const index = records.findIndex((item) => item.msgId === msgId)
            records.splice(index, 1)
            console.log('消息已删除', CUR_CHAT_RECORDS_KEY, records)
            showToast({
                message: i18n.global.t('message.chatMsgDelTipSuccess'),
                position: 'top',
                duration: 1500,
            })
        }
    }

    const findMessageIndexById = (params: IRecordsKey, msgId: string): number => {
        const key = GET_CHAT_RECORDS_KEY(params)
        const records = (GET_CHAT_RECORDS(key, 'data') as RecordsDirectResItem[]) || []
        return records.findIndex((item) => item.msgId === msgId)
    }

    /**
     * 当有新消息时，需要做以下操作
     * 一. 如果在会话页面，让聊天滚动到最底部。方便查看。
     * 二. 更新 topList chatList
     *     2.1 更新对应房间的 msgText 和 updatedAt("2025-01-09 10:57:10")
     *     2.2 更新对应房间的 unreadCount
     *     2.3 更新对应房间的排序。
     * @param params
     * @param newMessage
     * @param status
     * @constructor
     */
    const UPDATE_OR_ADD_MESSAGE_RECORD_ITEM = (params: IRecordsKey, newMessage: RecordsDirectResItem, status?: 0 | 1 | 2 | 3) => {
        // 通过 HAS_CHAT_RECORDS(params) 判断记录是否存在，
        // 理论上来说，只要是自己发的消息，都应该存在。
        // 有一种情况不存在，那就是接收方，自己没有给对方发过信息，但是收到了对方的信息。这个时候，不应该保存。一旦保存，会出问题。
        // 例如：保存之后，对应的记录中会有一条数据，然后进到会话页面时，如果重新刷新，就没有了缓存功能，还会影响分页。
        // 这里唯一要做的是刷新下 房间列表
        if (!HAS_CHAT_RECORDS(params)) {
            // const records = GET_CHAT_RECORDS(key, 'data') || []
            // records.push(data)
            updateRoomInfo(newMessage, {
                ...params,
            })
            console.log('当前会话记录不存在或者没有激活')
            return
        }

        const key = GET_CHAT_RECORDS_KEY(params)
        // 这里不能通过 || [] 来操作，因为 [] 并不是一个响应性的数据，理论上到了这 里，就一定会有值。因为HAS_CHAT_RECORDS(params)存在。
        let records = GET_CHAT_RECORDS(key, 'data') as RecordsDirectResItem[]
        if (!records) {
            SET_CHAT_RECORDS(params, [], true)
            records = GET_CHAT_RECORDS(key, 'data') as RecordsDirectResItem[]
        }

        const index = findMessageIndexById(params, newMessage.msgId)

        if (index !== -1) {
            records[index] = { ...records[index], ...newMessage, ...(status !== undefined && { SendStatus: status }) }
        } else {
            records.push(newMessage)
            // 注意，不管是自己发送的消息，还是别人发送的消息，都会进到这里来。
            // 如果是别人发送的消息，那么就需要更新房间的信息。
            // 如果是自己的消息，是不是也要更新消息，我觉得应该是需要的。
            updateRoomInfo(newMessage, params)
            nextTick(() => bus.emit(EventBusTypes.MESSAGE.SCROLL_BOTTOM))
        }
    }

    return {
        ADD_CHAT_RECORDS,
        DEL_CHAT_RECORDS_BY_ID,
        UPDATE_OR_ADD_MESSAGE_RECORD_ITEM,
    }
})
