import request from '@/utils/request.ts'

import { PageAndSize, Pagination2, Result } from '@/api'

import { ChatListParam, ChatRoomItem, RecordsDirectResItem, RoomToggleTopStatusParams, TalkParam } from '@/api/message2/type'

/**
 * 请求房间列表数据，需要分别请求 置顶房间和非置顶房间。
 * @param data
 */
export const fetchRoomList = (data: ChatListParam & PageAndSize) => {
    return request.post<any, Result<Pagination2<ChatRoomItem[]>>>('/api/messagev2/listDirect', data)
}

/**
 * 置顶或者取消置顶。
 */
export const toggleRoomTopStatus = (data: RoomToggleTopStatusParams) => {
    return request.post<any, Result<any>>('/api/messagev2/top', data, {
        headers: {
            isShowErrorTip: true,
        },
    })
}

/**
 * 删除房间。
 */
export const reqDeleteRoom = (data: { toFromId: number }) => {
    return request.post<any, Result<any>>('/api/messagev2/talkDelete', data, {
        headers: {
            isShowErrorTip: true,
        },
    })
}

/**
 * 发送消息
 * @param data
 */
export const reqSendDirect = (data: TalkParam) => {
    const ReportLogExtra = {
        codeMatchType: 'exclusion',
        codeValues: [80000, 90000],
    }
    return request.post<any, Result<any>>('/api/messagev2/sendDirect', data, {
        headers: {
            isShowErrorTip: true,
            isShowLoading: true,
            reportLogType: `MessageSend,extra=${JSON.stringify(ReportLogExtra)}`,
        },
    })
}

/**
 * 获取聊天记录
 * @param data
 */
export const reqChatRecordsForUser = (data: { toFromId: number; cursor: number } & PageAndSize) => {
    return request.post<any, Result<Pagination2<RecordsDirectResItem[]> & { cursor: number }>>('/api/messagev2/recordsDirect', data)
}

/**
 * 删除聊天记录
 * @param data
 *          -toFromId 聊天目标用户id
 */
export const delRecordsForUser = (data: { to_from_id: number; msg_ids: string[] }) => {
    return request.post<any, Result<any>>('/api/messagev2/msgDelete', data)
}

/**
 * 标记消息已读, 离开的时候调用
 * @param data
 */
export const reqReadChannel = (data: { toFromId: number }) => {
    return request.post<any, Result<{ toFromId: number }>>('/api/messagev2/clearUnread', data)
}

/**
 * 获取会话详情的信息。
 * isBlock 是否拉黑 0=否 1=是 页面上需要的是一个布尔值。
 * isTop 是否置顶 1=是 2=否, 页面上需要的是一个布尔值，
 */
export const reqTalkDetail = (data: { toFromId: number }) => {
    return request.post<any, Result<{ isBlock: number; isTop: number }>>('/api/messagev2/talkDetail', data)
}
