import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { useSystemStore } from '@/store/modules/system.ts'
import { useUserStore } from '@/store/modules/user.ts'
import useToggleStore from '@/store/modules/toggle.ts'

import { useVipLevelList, useUserConfig } from '@/store/modules/com.ts'
import { useGoldPurchaseVideo } from '@/store/modules/purchareVideo.ts'
import { useHomeStore } from '@/store/modules/home'
import { useVideoMountedStore } from '@/store/modules/comVideo'
import { useRecordStore } from '@/store/modules/recordStore.ts'
import { useRoomStore } from '@/store/modules/roomStore.ts'
import { useRecordMessageStore } from '@/store/modules/messageStore.ts'
import { useUnreadMessagesStore } from '@/store/modules/unreadMessagesStore.ts'
const pinia = createPinia()
pinia.use(createPersistedState())
pinia.use(({ store }) => {
    const initialState = JSON.parse(JSON.stringify(store.$state))
    store.$reset = () => {
        store.$patch(initialState)
    }
})

export default pinia
export { useSystemStore, useUserStore, useToggleStore, useVipLevelList, useGoldPurchaseVideo, useHomeStore, useUserConfig, useVideoMountedStore, useRecordStore, useRoomStore, useRecordMessageStore, useUnreadMessagesStore }
