import { ImSendMessageTypeKey, IReceiveMsgType, RecordsDirectResItem } from '@/api/message2/type'
import { IM_SEND_MESSAGE_TYPE, IM_SEND_MESSAGE_TYPE_MAPPING } from '@/config/constant.ts'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import { useUserStore } from '@/store'

interface SendMessageBody {
    text?: string
    url?: string
}

export const useTalk = () => {
    const { MY_USER_INFO } = useUserStore()
    /**
     * 创建发送消息的消息体
     * @param content 消息内容
     * @param type 消息类型（例如 TEXT 或 IMAGE）
     * @returns 返回一个包含消息体的对象，根据类型不同包含不同的字段
     */
    const createSendMessageBody = (content: string, type: ImSendMessageTypeKey): SendMessageBody => {
        switch (type) {
            case IM_SEND_MESSAGE_TYPE.TEXT:
                return {
                    text: content, // 处理文本消息
                }
            case IM_SEND_MESSAGE_TYPE.IMAGE:
                return {
                    url: content, // 处理图片 URL 消息
                }
            default:
                return {} // 默认返回空对象
        }
    }
    /**
     * 创建发送消息项
     * @param content 消息内容
     * @param type 消息类型（例如 TEXT 或 IMAGE）
     * @param msgId 消息 ID（可选）
     * @returns 返回包含消息体和消息项的对象
     */
    const createSendMessageItem = (content: string, type: ImSendMessageTypeKey, msgId?: string): RecordsDirectResItem => {
        const msgBody = createSendMessageBody(content, type)
        const msgType = IM_SEND_MESSAGE_TYPE_MAPPING[type] as IReceiveMsgType
        const newMsgId = msgId || 'msgId_' + nanoid()

        console.log(`生成发送消息项：msgId=${newMsgId}, msgType=${msgType}`) // 闹翻：日志打印，调试信息

        return {
            avatar: MY_USER_INFO.headSrc,
            extra: JSON.stringify({ ...msgBody, content: content }),
            msgId: newMsgId,
            msgType: msgType,
            nickname: MY_USER_INFO.nickname,
            sendTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            nowTime: dayjs().format('YYYY-MM-DD HH:mm:ss'), // 这里需要一个 nowTime, 用于用作两个时间的比较，因为是没有经过服务器的，所以，可以直接这样显示。
            sequence: 0,
            userId: MY_USER_INFO.id,
            SendStatus: 1, // 0 初始默认, 1 正在发送, 2 已送达, 3 发送失败（自定义的默认值）
            extraInfo: { ...msgBody, content: content },
        } as RecordsDirectResItem
    }

    /**
     * 从 `RecordsDirectResItem` 对象中提取消息内容。
     * 该函数优先从 `extraInfo.content` 字段获取内容，如果没有该字段，则尝试从 `extra` 字段中解析获取内容。
     * 如果 `extra` 是一个字符串，函数会尝试将其解析为 JSON 对象，然后获取其中的 `content` 字段。
     * 如果解析失败或字段不存在，则返回空字符串。
     *
     * @param {RecordsDirectResItem} item - 包含消息数据的记录项。可能包含 `extraInfo` 或 `extra` 字段，存储了消息的内容。
     * @returns {string} - 返回从记录中提取的消息内容。如果没有找到有效的内容，则返回空字符串。
     */
    const getMessageContent = (item: RecordsDirectResItem): string => {
        // 尝试从 `extraInfo` 对象中获取 `content` 字段。
        if (item.extraInfo?.content || item.extraInfo?.url) {
            return item.extraInfo?.content || item.extraInfo?.url || '' // 如果 `content` 存在，返回它。
        }

        // 如果 `extraInfo.content` 不存在，尝试从 `extra` 字段中提取内容。
        // 处理 extra 为对象的情况
        if (typeof item.extra === 'object' && item.extra !== null) {
            // 如果 `extra` 是对象，尝试直接从对象中获取 `content` 字段。
            return item.extra.content || item.extra.url || '' // 如果 `content` 不存在，返回空字符串。
        }

        // 如果 `extraInfo.content` 不存在，尝试从 `extra` 字段中提取内容。
        // 这里假设 `extra` 字段可能是一个 JSON 字符串，包含 `content` 字段。
        try {
            // 尝试解析 `extra` 字段的字符串为 JSON 对象。
            const newExtra = JSON.parse(item.extra)

            // 如果解析成功，返回 `newExtra.content`，否则返回空字符串。
            return newExtra?.content || newExtra.url || '' // 如果 `content` 字段不存在，返回空字符串。
        } catch (e) {
            // 如果 `extra` 字段无法解析为有效的 JSON，打印错误日志。
            console.error('JSON 解析失败，extra 字段格式不正确:', e)
        }

        // 如果都没有找到有效的 `content`，则返回空字符串。
        return '' // 默认返回空字符串。
    }
    const parseExtraInfo = (extra: any): Record<string, any> => {
        const extraInfo: Record<string, any> = {}
        try {
            if (typeof extra === 'string') {
                Object.assign(extraInfo, JSON.parse(extra))
            } else if (typeof extra === 'object') {
                Object.assign(extraInfo, extra)
            }
        } catch (error) {
            console.error('extra 信息解析失败', error)
        }
        return {
            content: extraInfo.url || extraInfo.content || '',
            ...extraInfo,
        }
    }

    return {
        parseExtraInfo,
        getMessageContent,
        createSendMessageBody,
        createSendMessageItem,
    }
}
