/**
 * 0：
 * 1. 需要更新房间的时间。SET_FIRST_RECEIVED_ROOM_LIST ROOM_LIST.value[index].lastMessageTimestamp = payload.LastUpdatedTimestamp
 * 2： chat页面中，如何拿到最新消息的了？allMessages 中做的处理。
 *      如果正在聊天的消息通道与接收的信息通道是一致的，会将新的消息，推入到allMessages对应通道中。详情见 aws.chatMessageHandler
 *      messageStore.allMessages[messageStore.currentChatRoomUserArn].ChannelMessages.push(payload)
 * 3: 在激活的聊天窗口，他是通过 通道id是否是自己的来区分。item.Sender.Arn === userStore.MY_USER_INFO.userArn
 * @example
 * <ChatList
 *      :headSrc="userInfo.headSrc"
 *      :isLoadMore="isLoadMore"
 *      :isLoading="isLoading"
 *      @loadMoreData="loadMoreData"
 *      :channelMessages="messageStore.allMessages[userInfo.userArn]?.ChannelMessages">
 * </ChatList>
 *  <div class="chat-list" v-for="(item, index) in channelMessages" :key="item.MessageId">
 *      RightChat v-if="item.Sender.Arn === userStore.MY_USER_INFO.userArn" :chatId="'chat' + item.MessageId" :item="item"></RightChat>
 *      <LeftChat v-else :item="item" :headSrc="headSrc" :chatIndex="index" :chatLen="channelMessages.length"></LeftChat>
 *      <div :id="'chat' + item.MessageId"></div>
 *  </div>
 */

import { IImResPayload } from '@/im/type.ts'
import { useRecordMessageStore, useUserStore } from '@/store'
import { useTalk } from '@/im/composables/useTalk.ts'
/**
 * 处理 消息对话。
 * 需要把数据存储到messageStore中。
 * 需要根据聊天室的id,将消息存储到对应的聊天室中。
 * 判断消息是否来自于我
 * @param data
 */
export const useChatMessageHandler = (data: IImResPayload) => {
    const { UPDATE_OR_ADD_MESSAGE_RECORD_ITEM } = useRecordMessageStore()
    const { MY_USER_INFO } = useUserStore()
    const { parseExtraInfo } = useTalk()
    const { from_id, to_from_id, talk_mode, body } = data
    // 需要判断，消息是否来自自己。
    // 如果消息是来自己，为了优化体验，在输入框发送的时候，就把相关的数据，加到对应的聊天室中了，所以，如果消息是来自于自己，只需要在对应的消息上，给他一个状态就行。

    // to_from_id 表示和谁聊。from_id 表示发送者的id
    // 表明这个消息是来自于自己。 来自于自己的消息，需要给他加一个状态。表示这条消息已经完成。没办法处理啊，之前的逻辑是在发送消息的时候，通过一个index来判断是不是这条消息。
    // 那这里，我们也不处理了，直接在接口返回后做状态更新。
    //  "talk_mode": 1,
    //  "from_id": 1000084,  // 这里就不一样了，这里可能是对方的id, 也有可能是自己的id
    //  "to_from_id": 1000113, 和谁聊天，比如说我和 1000113聊天，那么from_id就是我的id，to_from_id就是1000113， 这里永远是对方 的id

    // 这里其实可以直接判断 === to_from_id，就是表示是自己发送的出去的。
    if (from_id === MY_USER_INFO.id) {
        // 这里应该对当前内容进行更新，找到对应的消息，更新他的状态，msgId, sequence SendStatus
        return
    }

    const extraInfo = parseExtraInfo(body.extra)
    UPDATE_OR_ADD_MESSAGE_RECORD_ITEM(
        {
            fromId: from_id,
            toFromId: to_from_id,
            talkMode: talk_mode,
        },
        {
            avatar: body.avatar, // 发送消息的用户头像
            extra: extraInfo, // string Json string
            msgId: body.msg_id, // 消息id
            msgType: body.msg_type, // 消息类型 1=文本消息，3=图片消息
            nickname: body.nickname, // 发送消息的用户昵称
            sendTime: body.send_time, // 消息发送时间
            nowTime: body.now_time, // 消息发送时间
            sequence: body.sequence, // 消息序号
            userId: body.user_id, // 发送消息的用户id
            SendStatus: 0, //0初始默认 1正在发送 2已送达 3发送失败 这是一个自定义的默认值。
            extraInfo: extraInfo, // 额外信息
        },
    )
}
