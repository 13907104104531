/**
 * im websocket 连接
 * @todo - 参考demo中有个，正在聊天的设计，就是心跳包会返回一个 im.message.keyboard 的类型，表示好友是否正在输入文字，
 *       - 在我们现有的业务体系当中，这个功能，是没有的。
 *       - 他的原理 是，用户开始输入内容的时候，发送一个 消息给后台，监听输入框的变化，3s后如果没有继续输入，发送一个消息给后台，表示输入结束。
 * @example 延时3秒 发送键盘输入事件。这个功能，在参考demo中，只是有这个设计，实际上，他通过一个变量控制他的触发 。这个变量是 false
 * const onKeyboardPush = throttle(() => {
 *   ws.emit('im.message.keyboard', {
 *     to_from_id: props.toFromId
 *   })
 * }, 3000)
 * @todo -参考demo中有个，好友上线的设计，即用户登录之后，ws中会有个im.contact.status的类型，我们这里好像也不需要。
 * @todo -参考demo中有个，im.message.revoke 撤回消息的功能。
 * @todo -参考demo中有个，im.group.apply 入群通知
 * @todo -参考demo中有个，im.contact.apply 好友申请通知
 *
 */

// import { h } from 'vue'
// import { NAvatar } from 'naive-ui'
// import { useTalkStore, useUserStore } from '@/store'
// import { notifyIcon } from '@/constant/default'

import WsSocket from '@/im/plugins/websocket.ts'
import { useUserConfig, useUserStore, useRoomStore, useUnreadMessagesStore } from '@/store'
import { useChatMessageHandler } from '@/im/ws-events/talk.ts'
import { handleClearChannel, handleBlockUser, handleUnblockUser, handleResumeUser } from '@/im/ws-events/system.ts'
import { IImResPayload } from '@/im/type.ts'

// import EventTalk from './event/talk'
//
// import EventKeyboard from './event/keyboard'
// import EventLogin from './event/login'
// import EventRevoke from './event/revoke'
// import { getToken, isLogin } from './utils/auth'

/**
 * 这里是获取 websocket 的 url, 他原有的原有的逻辑是 如果不骨登录，让他重新加载。
 * 但是在我们的项目里，不存在这个说法，始终是登录的，他分两种情况 ，一种是游客身份，一种是注册用户
 * 这里暂时未定，但是个人认为，很有可能是通过 MY_USER_INFO.id 来识别
 */
const urlCallback = () => {
    const { IM_TOKEN } = useUserStore()
    const { USER_CONFIG_WS_URL } = useUserConfig()

    return `${USER_CONFIG_WS_URL}?token=${IM_TOKEN}`
}
const IM_EVENT_TYPES = {
    CHAT: 'im.message', // 私聊
    GROUP: 'chat', // 群聊
    /**
     * 绑定消息系统通知 （内部通知，自有业务，例如：用户恢复聊天，被删除，拉黑，取消拉黑等操作）
     */
    SYSTEM: {
        RESUME: 'im.system.resume', // 用户恢复聊天频道
        CLEAR_CHANNEL: 'im.system.clearChannel', // 通知接收人（在发送人列表）被删除
        BLOCK_USER: 'im.system.blockUser', // 通知接收人已被拉黑
        UNBLOCK_USER: 'im.system.notBlockUser', // 通知接收人已被取消拉黑
    },
    /**
     * 绑定消息系统通知 接收评论，关注，评论等。
     * "AT"      //  @人通知
     * "COLLECT" //  收藏通知
     * "LIKE"    //  点赞通知
     * "FOLLOW"  //  关注通知
     * "COMMENT" //  评论通知
     * "REPLY"   //  回复通知
     */
    NOTICE_TOTAL: 'sub.sys.message.video',
    NOTICE_CHILDREN: {
        AT: 'sub.sys.message.video.at',
        COLLECT: 'sub.sys.message.video.collect', // 还没有
        LIKE: 'sub.sys.message.video.like',
        FOLLOW: 'sub.sys.message.video.follow',
        COMMENT: 'sub.sys.message.video.comment',
        REPLY: 'sub.sys.message.video.reply',
    },
} as const

class Connect {
    private conn: WsSocket | null = null
    constructor() {
        this.conn = new WsSocket(urlCallback, {
            onError: (evt) => {
                console.error('WebSocket 连接失败:', evt)
            },
            /**
             * websocket 连接成功的时候，要做一个什么事。
             * 设置用户 在线状态
             * 获取聊天列表服务接口
             * 在我们项目中，默认打开的是首页，我们不需要设置在线状态，也不需要获取聊天列表。有专门聊天服务接口。
             */
            onOpen: (evt) => {
                console.log('WebSocket 连接打开:', evt)
                const roomStore = useRoomStore()
                roomStore.refresh()
            },

            onClose: (evt) => {
                console.error('WebSocket 连接关闭:', evt)
                //  他这里原逻辑是，关闭的时候，设置用户在线状态为离线。
            },
        })
        this.bindEvents()
    }

    private setNoticeMsgReadStatus(channelType: 'notice' | 'fens', val?: boolean) {
        const { setNoticeMsgReadStatus } = useUnreadMessagesStore()
        setNoticeMsgReadStatus(channelType, val).then(() => {})
    }
    /**
     * connection 连接，在 app.vue 里面调用, 重新登录之后，也需要重新连接，因为登录的账号可能会有变化。
     * urlCallback 里面会根据登录状态，返回不同的 url
     * @example import ws from "@/connect"
     * ws.connect()
     * @todo 这里是不是要先 close, 然后再 connection
     */
    connect() {
        // 没有 imToken, 或者不是一个真实注册的用户，直接返回
        const { IM_TOKEN, STORE_IS_REGISTERED } = useUserStore()
        if (!IM_TOKEN || !STORE_IS_REGISTERED) {
            this.disconnect()
            return
        }
        this.conn?.connection()
    }

    /**
     *  关闭，这里应该是退出应用了，在app的onUnMounted 里面调用, 重新登录的时候，也需要有。
     *
     */
    disconnect() {
        this.conn?.close()
    }

    // 检测是否连接
    isConnect() {
        return this.conn?.connect?.readyState === WebSocket.OPEN
    }

    emit(event: string, data: any) {
        console.log('connect emit', event, data)
        this.conn?.emit(event, data)
    }

    /**
     * 绑定事件 ,
     * chat 聊天通知
     * system 消息内部通知
     * notice 消息系统通知
     *
     */
    bindEvents() {
        this.onPing()
        this.onPong()
        this.bindSystemEvents()
        this.bindNoticeEvents()
        this.onImContactStatus()
        this.onImMessage()
        this.onEventError()

        this.conn?.on('webrtc_answer', () => {})
        this.conn?.on('webrtc_ice_candidate', () => {})
        this.conn?.on('webrtc_offer', () => {})
    }

    onPing() {
        this.conn?.on('ping', () => {
            console.log('Received ping event:', '')
            this.emit('pong', '')
        })
    }

    onPong() {
        this.conn?.on('pong', () => {
            console.log('Received pong event:', '')
        })
        this.conn?.on('connect', () => {})
    }

    /**
     * 消息事件，私人聊天，群聊。
     * 这里要做一个什么事呢？
     * @todo
     */
    onImMessage() {
        // 消息事件，私人聊天，群聊。
        // (data: any) => new EventLogin(data) 这里需要换成我们自己的逻辑，原aws里的代码。需要根据后台接口改扒改扒
        /**
         * 0：
         * 1. 需要更新房间的时间。SET_FIRST_RECEIVED_ROOM_LIST ROOM_LIST.value[index].lastMessageTimestamp = payload.LastUpdatedTimestamp
         * 2： chat页面中，如何拿到最新消息的了？allMessages 中做的处理。
         *      如果正在聊天的消息通道与接收的信息通道是一致的，会将新的消息，推入到allMessages对应通道中。详情见 aws.chatMessageHandler
         *      messageStore.allMessages[messageStore.currentChatRoomUserArn].ChannelMessages.push(payload)
         * 3: 在激活的聊天窗口，他是通过 通道id是否是自己的来区分。item.Sender.Arn === userStore.MY_USER_INFO.userArn
         * @example
         * <ChatList
         *      :headSrc="userInfo.headSrc"
         *      :isLoadMore="isLoadMore"
         *      :isLoading="isLoading"
         *      @loadMoreData="loadMoreData"
         *      :channelMessages="messageStore.allMessages[userInfo.userArn]?.ChannelMessages">
         * </ChatList>
         *  <div class="chat-list" v-for="(item, index) in channelMessages" :key="item.MessageId">
         *      RightChat v-if="item.Sender.Arn === userStore.MY_USER_INFO.userArn" :chatId="'chat' + item.MessageId" :item="item"></RightChat>
         *      <LeftChat v-else :item="item" :headSrc="headSrc" :chatIndex="index" :chatLen="channelMessages.length"></LeftChat>
         *      <div :id="'chat' + item.MessageId"></div>
         *  </div>
         */
        this.conn?.on(IM_EVENT_TYPES.CHAT, (data: IImResPayload) => {
            useChatMessageHandler(data)
        })
    }

    /**
     * 暂时没有发现。估摸着应该是类似于好友上线之类的通知。在我们的项目里，应该用不到
     * @todo 如果确定不需要，直接删除
     */
    onImContactStatus() {
        // 暂时没有发现。估摸着应该是类似于好友上线之类的通知。在我们的项目里，应该用不到
        this.conn?.on('im.contact.status', () => {
            // console.log('暂时没有发现。估摸着应该是类似于好友上线之类的通知。在我们的项目里，应该用不到', data)
        })
    }

    /**
     * 绑定消息系统通知 （内部通知，自有业务，例如：用户恢复聊天，被删除，拉黑，取消拉黑等操作）
     */
    bindSystemEvents() {
        this.onImSystemResume()
        this.onImSystemClearChannel()
        this.onImSystemBlockUser()
        this.onImSystemUnblockUser()
    }
    /**
     * 消息内部通知 用户恢复聊天频道
     *
     */
    onImSystemResume() {
        this.conn?.on(IM_EVENT_TYPES.SYSTEM.RESUME, (data: any) => {
            handleResumeUser(data)
        })
    }

    /**
     * 消息内部通知 通知接收人 （在发送人列表） 被删除
     */
    onImSystemClearChannel() {
        this.conn?.on(IM_EVENT_TYPES.SYSTEM.CLEAR_CHANNEL, (data: any) => {
            handleClearChannel(data)
        })
    }

    /**
     * 消息内部通知 用户被拉黑
     */
    onImSystemBlockUser() {
        this.conn?.on(IM_EVENT_TYPES.SYSTEM.BLOCK_USER, (data: any) => {
            handleBlockUser(data)
        })
    }

    /**
     * 消息内部通知 用户被解除拉黑
     */
    onImSystemUnblockUser() {
        this.conn?.on(IM_EVENT_TYPES.SYSTEM.UNBLOCK_USER, (data: any) => {
            handleUnblockUser(data)
        })
    }

    /**
     * 绑定消息系统通知 接收评论，关注，评论等。
     * 这里要做一个什么事呢？
     *      1 需要处理下他们的未读消息。通过setNoticeMsgReadStatus来处理，原则上，其实可以直接在NOTICE_TOTAL中做统一处理。但是
     * "at"      //  @人通知
     * "collect" //  收藏通知
     * "like"    //  点赞通知
     * "follow"  //  关注通知
     * "comment" //  评论通知
     * "reply"   //  回复通知
     * @todo 原则上，其实可以直接在 NOTICE_TOTAL的回调中做统一处理。但是这里有个隐患，假如后期 ，SYSTEM 的消息类型，也是 NOTICE_TOTAL，需要一堆的判断，所以干脆分开在具体的类型中自己处理。
     */
    bindNoticeEvents() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_TOTAL, (data: any) => {
            console.log('消息系统通知', data)
        })
        this.onImNoticeAt()
        this.onImNoticeCollect()
        this.onImNoticeLike()
        this.onImNoticeFollow()
        this.onImNoticeComment()
        this.onImNoticeReply()
    }

    /**
     * 被 @ 的时候，会收到这个通知
     */
    onImNoticeAt() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_CHILDREN.AT, (data: any) => {
            console.log('被 @ 的时候，会收到这个通知', data)
        })
    }

    /**
     * 收藏通知
     */
    onImNoticeCollect() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_CHILDREN.COLLECT, (data: any) => {
            console.log('收藏通知', data)
            this.setNoticeMsgReadStatus('notice', true)
        })
    }

    /**
     * 点赞通知
     */
    onImNoticeLike() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_CHILDREN.LIKE, (data: any) => {
            console.log('点赞通知', data)
            this.setNoticeMsgReadStatus('notice', true)
        })
    }

    /**
     * 关注通知
     */
    onImNoticeFollow() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_CHILDREN.FOLLOW, (data: any) => {
            console.log('关注通知', data)
            this.setNoticeMsgReadStatus('fens', true)
        })
    }

    /**
     * 被评论通知
     */
    onImNoticeComment() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_CHILDREN.COMMENT, (data: any) => {
            console.log('被评论通知', data)
            this.setNoticeMsgReadStatus('notice', true)
        })
    }

    /**
     * 回复通知
     */
    onImNoticeReply() {
        this.conn?.on(IM_EVENT_TYPES.NOTICE_CHILDREN.REPLY, (data: any) => {
            console.log('回复通知', data)
            this.setNoticeMsgReadStatus('notice', true)
        })
    }

    onEventError() {
        this.conn?.on('event_error', (data: any) => {
            console.error('WebSocket事件错误:', data)
            // window['$message']?.error(`错误代码: ${data.error_code} - ${data.error_message}`)
        })
    }
}

// 导出单例
export default new Connect()
