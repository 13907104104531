import { defineStore } from 'pinia'
import { useUserStore } from '@/store/modules/user'
import { ref, computed } from 'vue'
import { IChartRecordsItem, IChatExtraRecords, IRecordsKey, RecordsDirectResItem } from '@/api/message2/type'
import { useTalk } from '@/im/composables/useTalk.ts'
const extraParamsKey = ['page', 'size', 'cursor']
/**
 * 聊天记录管理
 */
export const useRecordStore = defineStore(
    'recordStore',
    () => {
        const { parseExtraInfo } = useTalk()
        const userStore = useUserStore()
        const ALL_CHAT_RECORDS = ref<Record<string, IChartRecordsItem>>({})
        const CUR_CHAT_RECORDS_KEY = ref<string>('')

        const ALL_CHAT_RECORDS_LEN = computed(() => Object.keys(ALL_CHAT_RECORDS.value).length)
        const CUR_CHAT_RECORDS_DATA = computed(() => {
            if (!CUR_CHAT_RECORDS_KEY.value) return []
            return ALL_CHAT_RECORDS.value[CUR_CHAT_RECORDS_KEY.value]?.data || []
        })

        /**
         * 获取聊天记录 的 key, 需要有一个 userId
         * @param params
         * @constructor
         */
        const GET_CHAT_RECORDS_KEY = (params: string | IRecordsKey): string => {
            if (typeof params === 'string') return params
            return `${userStore.MY_USER_INFO.id}__${params.toFromId}_${params.talkMode || 1}`
        }

        const SET_CUR_CHAT_RECORDS_KEY = (params: string | IRecordsKey) => {
            CUR_CHAT_RECORDS_KEY.value = GET_CHAT_RECORDS_KEY(params)
        }

        const HAS_CHAT_RECORDS = (params: string | IRecordsKey): boolean => {
            return Reflect.has(ALL_CHAT_RECORDS.value, GET_CHAT_RECORDS_KEY(params))
        }

        const GET_CHAT_RECORDS = (params: string | IRecordsKey, field?: 'data'): any => {
            const key = GET_CHAT_RECORDS_KEY(params)
            const record = ALL_CHAT_RECORDS.value[key]
            if (!record) return null
            return field ? record[field] : record
        }

        /**
         *
         * @param params
         * @param data
         * @param isFirst
         * @constructor
         */
        const SET_CHAT_RECORDS = (params: string | IRecordsKey, data: any[], isFirst: boolean) => {
            const key = GET_CHAT_RECORDS_KEY(params)
            const curRecord = GET_CHAT_RECORDS(key) as IChartRecordsItem | null
            const curData = curRecord ? curRecord.data : []
            const extraParams = typeof params === 'string' ? {} : (JSON.parse(JSON.stringify(params, extraParamsKey)) as Partial<IChatExtraRecords>)
            const newData = data.map((item) => {
                const extraInfo = parseExtraInfo(item.extra)
                return {
                    ...item,
                    extra: extraInfo,
                    extraInfo,
                }
            }) as RecordsDirectResItem[]
            if (!curRecord || (curData && Array.isArray(curData) && curData.length === 0)) {
                ALL_CHAT_RECORDS.value[key] = {
                    ...extraParams,
                    data: newData.sort((a, b) => new Date(a.sendTime).getTime() - new Date(b.sendTime).getTime()),
                }
                return
            }
            const mergedData = isFirst ? newData : [...curData, ...newData]
            ALL_CHAT_RECORDS.value[key] = {
                ...curRecord,
                ...extraParams,
                data: mergedData.sort((a, b) => new Date(a.sendTime).getTime() - new Date(b.sendTime).getTime()),
            }
        }

        return {
            ALL_CHAT_RECORDS, // 所有的聊天记录
            CUR_CHAT_RECORDS_KEY,
            ALL_CHAT_RECORDS_LEN,
            CUR_CHAT_RECORDS_DATA,
            HAS_CHAT_RECORDS,
            GET_CHAT_RECORDS,
            SET_CHAT_RECORDS,
            GET_CHAT_RECORDS_KEY,
            SET_CUR_CHAT_RECORDS_KEY,
        }
    },
    {
        // persist: {
        //     paths: ['ALL_CHAT_RECORDS'],
        //     storage: localStorage,
        // },
    },
)
