export const SIZE_NUM = 10
export const PAGE_NUM = 1
export const SEARCH_CACHE_COUNT = 100

export const NAV_KEY = {
    FOLLOWING: 'following',
    FOR_YOU: 'forYou',
}

export const BUS_TYPE = {
    ATTENTION: 'ATTENTION',
    LIKE: 'LIKE',
    COMMENT: 'COMMENT',
    COLLECT: 'COLLECT',
    MUTED: 'MUTED',
}

// 回复层级
export const REPLY_LEVEL = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
}
// 评论点赞状态0=未点赞，未踩 1=已点赞 2=已踩
export const COMMIT_LIKE_STATUS = {
    ZERO: 0,
    ONE: 1,
    TWO: 2,
}

// 视频点赞状态0=未点赞 1=已点赞
export const VIDEO_LIKE_STATUS = {
    ZERO: 0,
    ONE: 1,
}
// 视频点赞状态0=未点赞 1=已点赞
export const VIDEO_FOLLOW_STATUS = {
    ZERO: 0,
    ONE: 1,
}
// 视频收藏状态0=未收藏 1=已收藏
export const VIDEO_COLLECT_STATUS = {
    ZERO: 0,
    ONE: 1,
}

export const SEARCH_ACTIVE = {
    ZERO: 0, // Top
    ONE: 1, // Videos
    TWO: 2, // Users
    THREE: 3, // HashTags
}

export const CONTENT_TYPE_old = {
    TEXT: 'text/plain',
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    GIF: 'image/gif',
}

/**
 * 发送消息时的类型。
 */
export const CONTENT_TYPE = {
    TEXT: 'textDirect',
    IMAGE: 'imageDirect',
    VIDEO: 'videoDirect',
}

/**
 * im聊天 发送消息时的消息类型。
 */
export const IM_SEND_MESSAGE_TYPE = {
    TEXT: 'textDirect',
    IMAGE: 'imageDirect',
    VIDEO: 'videoDirect',
} as const
/**
 * im聊天，接收消息时的消息类型。
 */
export const IM_RECEIVE_MESSAGE_TYPE = {
    TEXT: 1,
    IMAGE: 3,
    VIDEO: 2,
} as const

// 通用函数：创建映射关系
const createMessageTypeMapping = <SourceType extends Record<string, string | number>, TargetType extends Record<string, string | number>>(sourceTypes: SourceType, targetTypes: TargetType): Record<string, string | number> => {
    const mapping: Record<string, string | number> = {}
    Object.keys(sourceTypes).forEach((key) => {
        const sourceType = sourceTypes[key]
        const targetType = targetTypes[key.toUpperCase()] // targetTypes 中的键值需要是大写

        if (sourceType && targetType) {
            mapping[sourceType] = targetType
        }
    })
    return mapping
}

/**
 * im 消息类型映射 发送端类型 -> 接收端类型
 */
export const IM_SEND_MESSAGE_TYPE_MAPPING = createMessageTypeMapping(IM_SEND_MESSAGE_TYPE, IM_RECEIVE_MESSAGE_TYPE)
/**
 * 反向映射：接收端类型 -> 发送端类型
 */
export const IM_REVERSE_MESSAGE_TYPE_MAPPING = createMessageTypeMapping(IM_RECEIVE_MESSAGE_TYPE, IM_SEND_MESSAGE_TYPE)
