import { IImRes, IImResPayload, IImSubSysResPayload } from '@/im/type.ts'

const cache = new Set<string>() // 用于存储已接收的 ackid，防止重复处理

const maxAttempts = 100 // 最大重试次数

const defaultEvent = {
    onError: (evt: any) => console.error('WebSocket Error:', evt), // 默认错误回调
    onOpen: (evt: any) => console.log('WebSocket Opened:', evt), // 默认连接成功回调
    onClose: (evt: any) => console.log('WebSocket Closed:', evt), // 默认连接关闭回调
}
type EventCallback = (...args: any[]) => void
class WsSocket {
    connect: WebSocket | null = null // WebSocket 实例
    config: any = {
        heartbeat: {
            setInterval: null, // 心跳定时器
            pingInterval: 20000, // 心跳发送间隔（毫秒）
            pingTimeout: 60000, // 心跳超时时间（毫秒）
        },
        reconnect: {
            lockReconnect: false, // 是否锁定重连
            setTimeout: null, // 重连定时器
            interval: [2000, 2500, 3000, 3000, 5000, 8000], // 重连的间隔时间（指数退避）
            attempts: maxAttempts, // 最大重连次数
        },
    }

    lastTime: number = 0 // 最后一次收到消息的时间戳
    onCallBacks: Record<string, EventCallback> = {} // 事件回调函数集
    defaultEvent: Record<string, EventCallback> = defaultEvent // 默认事件回调

    constructor(
        private urlCallBack: () => string, // 获取 WebSocket URL 的回调
        private events: Partial<typeof defaultEvent>, // 用户自定义事件回调
    ) {
        this.events = { ...this.defaultEvent, ...events } // 合并默认事件和自定义事件
    }

    /**
     * 注册事件回调
     * @param event 事件类型
     * @param callback 事件处理函数
     */
    on(event: string, callback: EventCallback): this {
        this.onCallBacks[event] = callback // 存储事件回调
        return this
    }

    /**
     * 加载 WebSocket 连接
     */
    loadSocket(): void {
        this.connect = new WebSocket(this.urlCallBack()) // 使用回调获取 URL 创建 WebSocket 连接
        console.log('loadSocket', this.connect)
        this.connect.onerror = this.onError.bind(this) // 错误回调
        this.connect.onopen = this.onOpen.bind(this) // 连接成功回调
        this.connect.onmessage = this.onMessage.bind(this) // 接收到消息回调
        this.connect.onclose = this.onClose.bind(this) // 连接关闭回调
    }

    /**
     * 确保 WebSocket 连接存在
     */
    connection(): void {
        if (this.connect === null) this.loadSocket() // 如果没有 WebSocket 连接，创建新连接
    }

    /**
     * 执行重连操作
     */
    reconnect(): void {
        if (this.config.reconnect.lockReconnect || this.config.reconnect.attempts <= 0) return // 如果已锁定重连或超过最大重试次数，直接返回

        this.config.reconnect.lockReconnect = true // 锁定重连
        this.config.reconnect.attempts-- // 减少重连次数

        const delay = this.config.reconnect.interval.shift() // 获取下次重连的延迟时间
        this.config.reconnect.setTimeout = setTimeout(() => {
            console.log(new Date().toLocaleString(), 'Attempting to reconnect to WebSocket...')
            this.connection() // 尝试重新连接
        }, delay || 10000) // 如果没有取到延迟，默认延迟 10 秒
    }

    /**
     * 解析接收到的消息
     * @param evt 消息事件
     */
    onParse(evt: MessageEvent): IImRes {
        return JSON.parse(evt.data) // 将消息数据解析为 JSON
    }

    /**
     * WebSocket 连接成功回调
     * @param evt 连接事件
     */
    onOpen(evt: Event): void {
        this.lastTime = Date.now() // 更新最后连接时间
        this.events.onOpen?.(evt) // 调用自定义 onOpen 回调

        // 重置重连配置
        this.config.reconnect.interval = [1000, 1000, 3000, 5000, 10000]
        this.config.reconnect.lockReconnect = false
        this.config.reconnect.attempts = maxAttempts

        this.heartbeat() // 开启心跳检测
    }

    /**
     * WebSocket 关闭连接回调
     * @param evt 关闭事件
     */
    onClose(evt: CloseEvent): void {
        this.events.onClose?.(evt) // 调用自定义 onClose 回调
        this.connect = null // 清空连接实例

        // 清除心跳定时器
        this.config.heartbeat.setInterval && clearInterval(this.config.heartbeat.setInterval)

        this.config.reconnect.lockReconnect = false // 解锁重连

        if (evt.code !== 1000) {
            this.reconnect() // 如果不是正常关闭（代码 1000），则尝试重连
        }
    }

    /**
     * WebSocket 错误回调
     * @param evt 错误事件
     */
    onError(evt: Event): void {
        this.events.onError?.(evt) // 调用自定义 onError 回调
    }

    /**
     * WebSocket 接收到消息回调
     * @param evt 消息事件
     * @example interface MessageEvent {
     *   data: string | ArrayBuffer | Blob;  // 消息内容,
     *   origin: string;                     // 发送源（通常在 postMessage 中有用）
     *   lastEventId: string;                // 事件 ID（在 WebSocket 中通常不使用）
     *   source: MessageEventSource | null;  // 消息源（WebSocket 中为 null）
     *   ports: MessagePort[];               // 传输通道（WebSocket 中通常为空）
     * }
     * string：当 WebSocket 收到文本消息时，data 是一个字符串。这通常是 JSON 格式的字符串数据，可以使用 JSON.parse() 将其解析为 JavaScript 对象。包含了消息的类型 event，与内容 payload，
     * ArrayBuffer：当 WebSocket 收到二进制数据时，data 是一个 ArrayBuffer，这通常用于传输二进制数据。
     * Blob：当 WebSocket 收到 Blob 数据时，data 是一个 Blob 对象。这种情况常用于上传文件或图像等二进制数据。
     */
    onMessage(evt: MessageEvent): void {
        this.lastTime = Date.now() // 更新最后收到消息时间

        const data = this.onParse(evt) // 解析消息

        // ping 和 pong 应该都不需要处理，ping是心跳检测，pong是回应ping
        if (data.event === 'pong') {
            console.log('Received pong event:', data.payload)
            return // 如果是 pong 消息，直接返回
        }

        // 如果消息包含 ackid，发送 ack 确认
        if (data.ackid) {
            this.connect?.send(`{"event":"ack","ackid":"${data.ackid}"}`)
            if (cache.has(data.ackid)) return // 如果 ackid 已处理过，直接返回
            cache.add(data.ackid) // 缓存 ackid，避免重复处理
        }
        console.log('websockedt onMessage:', data) // 打印接收到的消息
        // 调用绑定的事件回调 data.payload, evt.data
        // @todo 这里要对 `data.event` 做处理，确保返回的 data.event 是与我们约定的一致，做个映射关系。
        let dataEvent = data.event
        this.executeCallback(dataEvent, data.payload, evt)
        if (dataEvent === 'sub.sys.message.video' && 'message_type' in data.payload && data.payload?.message_type) {
            dataEvent = `${data.event}.${data.payload.message_type}`
            this.executeCallback(dataEvent, data.payload, evt)
        }
    }

    // 封装回调执行逻辑的函数
    private executeCallback(event: string, payload: IImResPayload | IImSubSysResPayload, evt: MessageEvent): void {
        const callback = this.onCallBacks[event]
        if (callback) {
            callback(payload, evt.data)
        } else {
            console.warn(`WebSocket message event [${event}] not bound...`)
        }
    }

    /**
     * 心跳检测，每隔一定时间发送 ping 消息
     */
    heartbeat(): void {
        this.config.heartbeat.setInterval && clearInterval(this.config.heartbeat.setInterval) // 清除旧的心跳定时器
        this.config.heartbeat.setInterval = setInterval(() => {
            this.ping() // 发送 ping 消息
        }, this.config.heartbeat.pingInterval) // 根据配置的 ping 间隔时间发送
    }

    /**
     * 发送 ping 消息
     */
    ping(): void {
        this.connect?.send(JSON.stringify({ event: 'ping' })) // 发送 ping 消息
    }

    /**
     * 发送消息
     * @param message 消息内容
     */
    send(message: any): void {
        if (this.connect && this.connect.readyState === WebSocket.OPEN) {
            this.connect.send(typeof message === 'string' ? message : JSON.stringify(message)) // 如果连接正常，发送消息
        } else {
            alert('WebSocket 连接已关闭') // 如果连接关闭，提示用户
        }
    }

    /**
     * 关闭 WebSocket 连接
     */
    close(): void {
        this.connect?.close(1000, '主动断开链接') // 关闭连接
        this.config.heartbeat.setInterval && clearInterval(this.config.heartbeat.setInterval) // 清除心跳定时器
    }

    /**
     * 发送事件
     * @param event 事件类型
     * @param payload 事件数据
     */
    emit(event: string, payload: any): void {
        if (this.connect && this.connect.readyState === WebSocket.OPEN) {
            this.connect.send(JSON.stringify({ event, payload })) // 发送事件消息
        } else {
            console.error('WebSocket connection closed...', this.connect) // 如果连接已关闭，输出错误
        }
    }
}

export default WsSocket
