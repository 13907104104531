export default {
    'hi-IN': {
        // सामान्य
        common: {
            del: 'हटाएं',
            confirm: 'पुष्टि करें',
            cancel: 'रद्द करें',
            back: 'वापस',
            message: 'संदेश',
            upload: 'अपलोड',
            share: 'शेयर करें',
            share_desc: '',
            save: 'सहेजें',
            more: 'और',
            noMore: 'कोई और नहीं',
            loading: 'लोड हो रहा है',
            loadFail: 'लोड विफल',
            loadFailTip: 'पुनः लोड करने के लिए क्लिक करें',
            loadSuccess: 'लोड सफल',
            loadSuccessTip: 'लोड किए गए {num} आइटम',
            down_refresh: 'ताज़ा करने के लिए नीचे खींचें',
            release_refresh: 'ताज़ा करने के लिए छोड़ें',
            search_placeholder: 'कृपया कीवर्ड दर्ज करें',
            search: 'खोजें',
            CNY_symbol: '¥',
            USD_symbol: '$',
            unmute: 'अनम्यूट करें',
            pulling_text: 'ताज़ा करने के लिए नीचे खींचें',
            loosing_text: 'ताज़ा करने के लिए छोड़ें',
            loading_text: 'लोड हो रहा है…',
            success_text: 'ताज़ा सफल',
            expand_text: 'विस्तार करें',
            collapse_text: 'संकुचन',
            justNow: 'अभी हाल ही में',
            minuteAgo: '{num} मिनट पहले', // 统一使用复数形式
            hourAgo: '{num} घंटे पहले', // 统一使用复数形式
            dayAgo: '{num} दिन पहले', // 统一使用复数形式
        },
        request: {
            error: 'अनुरोध में त्रुटि',
            timeout: 'अनुरोध समय समाप्त',
            e401: 'टोकन की समयसीमा समाप्त',
            e403: 'कोई अनुमति नहीं',
            e404: 'अनुरोध पता त्रुटि',
            e500: 'सर्वर त्रुटि',
        },
        share: {
            name: 'शेयर करें',
            title: 'अब दोस्तों के साथ शेयर करें',
            copy_link: 'लिंक कॉपी करें',
            copy_link_success: 'लिंक कॉपी सफल',
            copy_link_fail: 'लिंक कॉपी विफल',
        },
        follow: {
            follow: 'अनुसरण करें',
            followed: 'अनुसरण किया गया',
            mutual: 'आपसी',
            following: 'अनुसरण कर रहे हैं',
            followers: 'अनुसरण करने वाले',
            likes: 'लाइक्स',
            suggested: 'सुझाए गए',
            unfollow_tip_title: 'सूचना',
            unfollow_tip_content: 'क्या आप वास्तव में इस उपयोगकर्ता को अनफॉलो करना चाहते हैं?',
            follow_success_toast_tip: 'अनुसरण सफल',
            unfollow_success_toast_tip: 'अनफॉलो सफल',
            share_videos_tip: 'अपने नवीनतम मजेदार वीडियो शेयर करें',
        },
        comment: {
            reply: 'उत्तर दें',
            expand_text: 'विस्तार करें',
            more: 'और',
            comment_success_tip: 'टिप्पणी सफल।',
            collapse_text: 'संकुचन',
            expand_more: 'और विस्तार करें',
            expand_count_reply: 'विस्तार {count} उत्तर',
            del_comment_tip_title: 'सूचना',
            del_comment_tip_content: 'क्या आप वास्तव में इस टिप्पणी को हटाना चाहते हैं?',
        },
        upload: {
            title: 'अपलोड',
            desc: 'अपने नवीनतम मजेदार वीडियो शेयर करें',
            upload_success_toast_tip: 'अपलोड सफल',
            upload_fail_toast_tip: 'अपलोड विफल',
        },
        tip: {
            agreement: 'कृपया उपयोगकर्ता समझौते और उपयोगकर्ता गोपनीयता को पढ़ें और सहमत करें।',
        },
        video: {
            loading_desc: 'वीडियो लोड हो रहा है…',
            buffering_desc: 'वीडियो बफरिंग...',
            error_desc: 'वीडियो लोड करने में विफल। कृपया बाद में पुनः प्रयास करें',
            transcoding: 'ट्रांसकोडिंग...',
            transcoding_pop_desc: 'वीडियो ट्रांसकोड किया जा रहा है। \n कृपया कुछ समय प्रतीक्षा करें',
            transcoding_error_desc: 'वीडियो ट्रांसकोडिंग विफल',
            video_purchase_guide: {
                title: '',
                welcome: 'सदस्यता के लिए धन्यवाद।',
                vip_desc1: 'मासिक सदस्यता के साथ मुफ्त देखने',
                vip_desc2: 'सिक्का सदस्यता',
                gold_desc1: 'सदस्यता की आवश्यकता नहीं, वीडियो अनलॉक करने के लिए भुगतान करें',
                gold_desc2: 'सिक्कों से',
                free_preview: 'मुफ्त पूर्वावलोकन {time} सेकंड',
                try_now: 'अभी आज़माएँ',
                vip_btn_text: 'अभी सदस्यता लें',
                gold_btn_text: 'अभी अनलॉक करें',
            },
            gold_purchase_video: {
                btn_text: '{gold} खरीदें',
                confirm_title: 'सिक्कों की कमी',
                confirm_content: 'क्या आप टॉप अप करना चाहते हैं?',
                confirm_success_toast_tip: 'हटाना सफल',
                gold_pop_title: 'लॉगिन अनलॉक',
                gold_pop_content: 'देखे गए {count} आइटम, अधिक अनलॉक करने के लिए लॉगिन करें',
                gold_pop_btn_text: 'अभी रिचार्ज करें',
            },
        },
        login: {
            name: '91Live में लॉगिन करें',
            desc: 'अपने खाते का प्रबंधन करें, सूचनाएँ चेक करें, वीडियो पर टिप्पणी करें, और अधिक।',
            phone_placeholder: 'फोन नंबर',
            phone_err_message: 'एक मान्य फोन नंबर दर्ज करें',
            profile: 'कृपया पढ़ें और सहमत हों',
            profile1: '《उपयोगकर्ता समझौता》',
            profile2: 'और',
            profile3: '《उपयोगकर्ता गोपनीयता》',
            submit_text: 'कोड भेजें',
        },
        code: {
            title: '4-अंकों का कोड दर्ज करें',
            desc: 'आपका कोड भेजा गया था ',
            resend_code: 'कोड फिर से भेजें',
        },
        home: {
            comment_placeholder: 'टिप्पणी जोड़ें...',
            go_to_login: 'लॉगिन',
            top_msg: 'देखे गए {time} आइटम, अधिक अनलॉक करने के लिए पंजीकरण करें!',
            top_msg1: 'वीडियो देखकर सिक्के कमाएँ।',
        },
        search: {
            navTabs: ['शीर्ष', 'वीडियो', 'उपयोगकर्ता', 'हैशटैग'],
            video_title: 'वीडियो',
            user_title: 'उपयोगकर्ता सूची',
            hashtags_posts: 'पोस्ट',
        },
        discover: {
            title: 'खोजें',
            popular_models: 'लोकप्रिय मॉडल',
            more: 'और',
            hashtags: 'हैशटैग',
            shooting_games: 'शूटिंग गेम्स',
        },
        profile: {
            title: 'प्रोफ़ाइल',
            edit_profile: 'प्रोफ़ाइल संपादित करें',
            edit_profile_desc: 'अपनी प्रोफ़ाइल संपादित करें',
            edit_profile_desc1: 'अपनी प्रोफ़ाइल संपादित करें',
            edit_profile_desc2: 'अपनी प्रोफ़ाइल संपादित करें',
            edit_profile_desc3: 'अपनी प्रोफ़ाइल संपादित करें',
            edit_profile_desc4: 'अपनी प्रोफ़ाइल संपादित करें',
            edit_profile_desc5: 'अपनी प्रोफ़ाइल संपादित करें',
        },
        creation: {
            desc_placeholder: 'विवरण जोड़ें...',
            edit_cover: 'कवर संपादित करें',
            edit_cover_error: 'त्रुटि',
            submit: 'पोस्ट करें',
            submit_success_toast_tip: 'पोस्ट सफल',
            label_pop: {
                title: 'लेबल',
                submit: 'समाप्त',
            },
            videoTypePop: {
                title: 'वीडियो प्रकार',
                public: 'सार्वजनिक',
                public_desc: 'सभी (डिफ़ॉल्ट)',
                gold: 'सिक्का',
                gold_desc: 'केवल सिक्के देख सकते हैं',
                vip: 'वीआईपी',
                vip_desc: 'केवल वीआईपी देख सकते हैं',
                private: 'निजी',
                private_desc: 'केवल आप देख सकते हैं',
            },
            gold_pop: {
                title: 'सिक्का चयन',
                submit: 'सहेजना',
            },
        },
        wallet: {
            title: `{name} का बैलेंस`,
            wallet_desc1: 'सिक्के',
            wallet_desc2: 'सिक्के प्राप्त करें',
            RechargeCoins: 'सिक्के रिचार्ज करें',
            Giveaway: 'उपहार',
            Services: 'सेवाएँ',
            TransactionHistory: 'लेन-देन इतिहास',
            HelpAndFeedback: 'सहायता और प्रतिक्रिया',
            recharge_coins_item_desc: 'अनुमानित कुल पुरस्कार',
            current_payout_desc: 'वर्तमान भुगतान',
            next_payout_desc: 'अगला भुगतान',
            empty_transaction_history: 'अभी तक कोई लेन-देन नहीं',
            empty_transaction_history_desc: 'अपनी खरीद, रिफंड, और \n विनिमय का रिकॉर्ड रखें',
            buyGolds: {
                title: 'सिक्के खरीदें',
                gold_total_title: 'सिक्के बैलेंस',
                submit: 'प्राप्त करें',
                desc: 'जारी रखने पर, आप आभासी वस्तुओं की नीति से सहमत होते हैं। यदि आप इस खरीद से बाहर निकलते हैं, तो आप अतिरिक्त सिक्के भी खो देंगे।',
            },
        },
        message: {
            title: 'इनबॉक्स',
            on_top: 'शीर्ष पर',
            un_top: 'अनटॉप',
            new_followers: 'नए अनुयायी',
            new_followers_empty: 'जब कोई नया आपको अनुसरण करता है, तो आप इसे यहां देखेंगे।',
            followers_notifications_title: 'नए अनुयायी',
            followers_notifications_desc: 'यहाँ अपने नए अनुयायियों की जांच करें..',
            sending: ' भेजा जा रहा है',
            delivered: 'प्राप्त हुआ',
            sending_failed: 'भेजने में विफल',
            allActivity: {
                title: 'सभी गतिविधियाँ',
                desc: 'यहाँ सभी गतिविधियाँ देखें…',
                menu: ['सभी गतिविधियाँ', 'लाइक्स और पसंदीदा', 'टिप्पणियाँ', 'उल्लेख और टैग'],
            },
            chatSetting: {
                title: 'चैट सेटिंग्स',
                masking: 'ब्लॉक करें',
                put_top: 'शीर्ष पर पिन करें',
                masking_pop_title: 'ब्लॉक',
                masking_pop_message: 'क्या आप वास्तव में इस उपयोगकर्ता को ब्लॉक करना चाहते हैं?',
                block_confirm: 'क्या आप वास्तव में इस उपयोगकर्ता को ब्लॉक करना चाहते हैं?',
                block_success: 'ब्लॉक सफल',
                unblock: 'अनब्लॉक',
                unblock_confirm: 'क्या आप वास्तव में इस उपयोगकर्ता को अनब्लॉक करना चाहते हैं?',
                unblock_success: 'अनब्लॉक सफल',
                delete: 'हटाएं',
                delete_confirm: 'क्या आप वास्तव में इस चैट को हटाना चाहते हैं?',
                delete_success: 'हटाना सफल',
                report: 'रिपोर्ट करें',
                report_confirm: 'क्या आप वास्तव में इस उपयोगकर्ता की रिपोर्ट करना चाहते हैं?',
                report_success: 'रिपोर्ट सफल',
            },
            chatLastMsgImg: '[छवि संदेश]',
            chatMsgCopyBtnText: 'कॉपी',
            chatMsgDelBtnText: 'हटाना',
            chatMsgDel: 'संदेश हटा दिया गया', // 消息已经删除。
            chatMsgDelTitle: 'सुझाव',
            chatMsgDelTip: 'क्या आप यह संदेश हटाना चाहते हैं {content} यह संदेश', // 是否删除这条消息
            chatMsgDelImgTip: 'क्या आप सच में इस छवि को हटाना चाहते ',
            chatMsgDelTipSuccess: 'सफलतापूर्वक हटाया गया',
        },
        me: {
            work_vip_filter: {
                vip: 'वीआईपी',
                gold: 'सिक्का',
            },
            work_public_filter: {
                latest: 'नवीनतम',
                popular: 'लोकप्रिय',
            },
            upload: 'अपलोड',
            desc: 'अपने नवीनतम मजेदार वीडियो शेयर करें',
            likesDialogMsg: `"{name}" को कुल {num} लाइक्स मिले हैं"`,
            user_settings: {
                title: 'सेटिंग्स और गोपनीयता',
                account: 'खाता',
                privacy: 'गोपनीयता',
                security_and_permissions: 'सुरक्षा और अनुमतियाँ',
                login: 'लॉगिन',
                logout: 'लॉगआउट',
            },
            user_panel: {
                edit_label: 'संपादित करें',
                del_label: 'हटाएं',
                confirm_title: 'हटाएं',
                confirm_content: 'क्या आप वाकई इस काम को हटाना चाहते हैं?',
                confirm_success_toast_tip: 'हटाना सफल',
            },
            edit_profile: {
                title: 'प्रोफ़ाइल संपादित करें',
                avatar_desc: 'अपना अवतार बदलें',
                about_you: 'आपके बारे में',
                phone: 'फोन',
                phone_placeholder: 'अपना फोन नंबर दर्ज करें',
                name: 'नाम',
                edit_nickname: 'उपनाम संपादित करें',
                edit_nickname_success: 'उपनाम संपादन \n सफल',
                name_placeholder: 'अपना नाम दर्ज करें',
                introduction: 'परिचय',
                edit_introduction: 'परिचय संपादित करें',
                edit_introduction_success: 'परिचय संपादन \n सफल',
                introduction_placeholder: 'अपना परिचय दर्ज करें',
            },
            vip: {
                title: 'वीआईपी सदस्यता',
                desc: 'वीआईपी उपयोगकर्ताओं को अधिक सिक्के मिलते हैं',
                level_item_title: `{duration} माह`,
                submit_text: 'सक्रिय करें',
                submit_success: 'सक्रिय',
                submit_success_toast_tip: 'सक्रिय \n सफल',
            },
            circle: {
                title: 'चलो',
                desc: 'अपने नवीनतम मजेदार वीडियो शेयर करें',
            },
            balance: {
                title: 'बैलेंस',
                balance: 'बैलेंस',
            },
            settings_and_privacy: {
                title: 'सेटिंग्स और गोपनीयता',
                account: 'खाता',
                account_desc: 'अपने खाता सेटिंग्स बदलें',
                notification: 'सूचनाएँ',
                notification_desc: 'अपनी सूचना सेटिंग्स बदलें',
                privacy: 'गोपनीयता',
                privacy_desc: 'अपनी गोपनीयता सेटिंग्स बदलें',
                help: 'सहायता',
                help_desc: 'अपने खाते से संबंधित सहायता प्राप्त करें',
                about: 'हमारे बारे में',
                about_desc: 'हमारे बारे में जानकारी',
                logout: 'लॉगआउट',
                logout_desc: 'अपने खाते से लॉगआउट करें',
                logout_confirm: 'क्या आप वास्तव में लॉगआउट करना चाहते हैं?',
                logout_confirm_text: 'लॉगआउट',
            },
        },
    },
}
