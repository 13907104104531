import { defineStore } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoomStore, useUserStore } from '@/store'
import { reqGetNoticeStatus, reqReadNotice } from '@/api/message'

export const useUnreadMessagesStore = defineStore('unreadMessages', () => {
    const userStore = useUserStore()
    const roomStore = useRoomStore()

    // 存储每种类型的未读状态
    const HAS_NEW_NOTICE_MSG = ref(false)
    const HAS_NEW_FOLLOW_MSG = ref(false)

    // 计算总的未读消息数量
    // @todo 由于房间列表与未读消息计数在设计中是耦合在一起的，导致在分页加载过程中，若当前页加载的是已读消息，而未读消息存在于下一页，可能会导致 tabBar 未读消息显示异常。
    const roomListUnreadMsgCount = computed(() => {
        return roomStore.roomList.reduce((total, room) => total + room.unreadNum, 0)
    })

    // 计算是否有未读消息（包括房间和通知）
    const unreadMsgStatus = computed(() => {
        return HAS_NEW_NOTICE_MSG.value || HAS_NEW_FOLLOW_MSG.value || roomListUnreadMsgCount.value > 0
    })

    // 统一处理设置未读消息状态的函数
    // 统一处理设置未读消息状态的函数
    const setNoticeMsgReadStatus = async (channelType: 'notice' | 'fens', val: boolean = false) => {
        const statusMap = {
            notice: HAS_NEW_NOTICE_MSG,
            fens: HAS_NEW_FOLLOW_MSG,
        }

        const oldStatus = statusMap[channelType]?.value || false
        statusMap[channelType].value = val

        try {
            await reqReadNotice({ channelType })
            console.log(`${channelType}通知已读`)
        } catch (error) {
            console.error(`设置${channelType}通知已读失败:`, error)
            statusMap[channelType].value = oldStatus // 保持状态一致性
        }
    }

    // 请求接口并更新房间列表中的未读消息状态
    const fetchNoticeStatus = async () => {
        try {
            const res = await reqGetNoticeStatus()
            if (res.code !== 0) return

            const channelData = res.data.channelData || []
            channelData.forEach((item) => {
                if (item.channelType === 'notice') {
                    HAS_NEW_NOTICE_MSG.value = !item.status
                }
                if (item.channelType === 'fens') {
                    HAS_NEW_FOLLOW_MSG.value = !item.status
                }
            })
        } catch (error) {
            console.error('获取未读消息失败:', error)
        }
    }
    /**
     * touristLogin 游客身份也有 id 了，那要如何判断呢。
     */
    watch(
        () => userStore.MY_USER_INFO.id,
        async (newVal) => {
            if (newVal && userStore.STORE_IS_REGISTERED) {
                await fetchNoticeStatus()
            }
        },
    )
    onMounted(async () => {
        if (userStore.STORE_IS_REGISTERED) {
            await fetchNoticeStatus()
        }
    })

    return {
        roomListUnreadMsgCount,
        unreadMsgStatus,
        fetchNoticeStatus,
        HAS_NEW_NOTICE_MSG,
        HAS_NEW_FOLLOW_MSG,
        setNoticeMsgReadStatus, // 替代 SET_NEW_NOTICE_MSG 和 SET_NEW_FOLLOW_MSG
    }
})
